<template>
    <div class="d-flex">
        <div v-for="cat in ratingCategories" :key="cat.id" class="mr-4"> 
            <div class="catTitle">{{cat.title}}</div>
            <v-radio-group row dense>
                <div v-for="(rating) in ratings" :key="rating.id" class="ratings">
                    <div>{{rating.char}}</div>
                    <div>
                        <v-radio mandatory :value="rating.value" @click="fSetRatingStd()"></v-radio>
                    </div>
                </div>
            </v-radio-group>
        </div>
    </div>
</template>

<script>

export default {

	props: ['stdId'],

	data: () => ({
        ratings: [
            { id: 1, char:"X", value:1   },
            { id: 2, char:"M", value:5   },
            { id: 3, char:"O", value:3   },
            { id: 4, char:"V", value:6.5 },
            { id: 3, char:"G", value:8   },
            { id: 4, char:"E", value:10  },
        ],
        ratingCategories: [
            { id: 1, title:"Product",      descr:"Team: werking van applicatie, UX, bevat alle functionaliteiten, .. "   },
            { id: 2, title:"Presentatie",  descr:"Individueel: houding, taalgebruik, .."   },
            { id: 3, title:"Groepswerk",   descr:"Individueel: samenwerking, gebruik scrum en scrumtools, .."   },
            { id: 4, title:"techniek",     descr:"Individueel: toepassing van gevraagde technieken, .."   },
        ],
	}),

    created() {
      
	},

    methods: {
        fSetRatingStd: function() {
            console.log("in fSetRatingStd()");
        }

	},
};
</script>
<style scoped>
    .ratings div {
        border: 0px solid red;
        text-align: center;
        width:30px;
    }

    .ratings div:nth-child(2) {
        margin-left:3px;
    }

    .catTitle {
        font-weight: 600;
        margin-left: 8px;
    }

    /* correctie standaard input settings vuetify */
    .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }
</style>
