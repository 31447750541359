import axios from "axios";
import store from '../store'

//import baseUrlAction from './api_ini.js'

export const getStudents = async () => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        let url = baseUrlAction + 'getStudents';
        console.log("get students url = "+url)
        const response = await axios.get(url);
        //console.log("response (met config en header info) FROM student.js = ", response);
        return response;
        // hier moet hij complete response.data teruggeven!!, want je wilt ook de succes en de sMessage in de view afhandelen
        // hier kent hij this.$root bijvoorbeeld, dus geen emit.snackBar, want deze file zit niet in de scope
    } catch (err) {
        console.error(err);
    }
};

//getTeamsFromTeamId
export const getTeamsFromTeamId = async (id_parent_team) => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        console.log("in getTeamsFromTeamId. id_parent_team =" + id_parent_team);
        const payload = { id_parent_team: id_parent_team };   
        const response = await axios.post(baseUrlAction + 'getTeamsFromTeamId', payload);
        //console.log("in edu.js, response van getTakenPerModId:", response);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};


