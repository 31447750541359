<template>

    <div class="grey lighten-5">
        <v-row
            no-gutters
        >
        <v-col
            sm="12"
            md="3"
        >
            <v-card
                class="pa-2 ma-2"
            >
                <v-card-title>Modules</v-card-title>
                <v-card-text>Blaat</v-card-text>
                <v-card-actions>
                    <v-btn
                        color="deep-purple lighten-2" text outlined>Nieuw</v-btn>
                    </v-card-actions>
            </v-card>
        </v-col>
       
        <v-treeview :items="teams">
             <template slot="label" slot-scope="props">
                <!-- <router-link :to="props.item.to" v-if="props.item.to">{{ props.item.name }}</router-link>
                <span v-else>{{ props.item.name }}</span> -->
                <div class="d-flex">
                    <div class="stdName">{{ props.item.name }}</div>
                    <evalCompBeo v-if=!props.item.bRoot :stdId='props.item.stdId'></evalCompBeo>
                </div>
            </template>
        </v-treeview>
      </v-row>
    </div>
</template>

<script>
import evalCompBeo from "@/components/edu_pages/evalCompBeo";
//import { getDoelen, getModules, getRijenPerTaakId} from "@/api/edu.js";
import { getStudents, getTeamsFromTeamId} from "@/api/student.js";

export default {

	components: {
        evalCompBeo,
	},

	data: () => ({
        students: [],
        planGroepMod: [],
        teams: [],
        beoTree: {
            module: { 
                id: 1, 
                oModule: {},
                students: [
                    {
                        id: 1,
                        oStd: {},
                        catBeos: [
                            {
                                id: 0,
                                oCatBeo: {},
                                ratings: [
                                    {
                                        id: 0,
                                        oRating: {},
                                    }
                                ]
                            }
                        ]
                    }
                ] 
            }
        }
	}),

    created() {
        this.fGetStudents();
        this.fGetTeamsFromTeamId(1);
	},

    methods: {
        fGetStudents: function() {
            getStudents().then((apiData) => {
                console.log("getStudents() apiData:", apiData);
                if (apiData.data.bSuccess) {
                    this.students = apiData.data.data;
                    this.$root.$emit("showSnackbar", { text: apiData.data.sMessage, type: "success", time: 7000 });
                    //console.log("in eval.vue, created, getStudents() this.students:", this.students);
                } else {
                    this.$root.$emit("showSnackbar", { text: "9a) "+apiData.data.sMessage, type: "error", time: 7000 });
                    //console.log("in eval.vue, created, getStudents() apiData.sMessage:", apiData.sMessage);
                }
            });
        },

        fGetTeamsFromTeamId: function(id) {
            //console.log("in fGetTeamsFromTeamId. Meegegeven parent_id="+id);
            getTeamsFromTeamId(id).then((apiData) => {
                //console.log("getTeamsFromTeamId() apiData:", apiData);
                if (apiData.bSuccess) {
                    let teams = apiData.data;
                    let oTeams = {};
                    teams.forEach( function(item) {
                        //console.log("item = ", item, "index=", index)
                        //text += index + ": " + item + "<br>"; 
                        if(!oTeams[item.naam_team]) {
                            oTeams[item.naam_team] = [{ stdNaam: item.naam, stdId: item.stdId}]
                        } else {
                            oTeams[item.naam_team].push({ stdNaam: item.naam, stdId: item.stdId})
                        }
                    });
                    console.log("oTeams = ", oTeams)
                    const teamKeys = Object.keys(oTeams);
                    console.log("teamKeys = ", teamKeys);

                    let aTeams = [];
                    teamKeys.forEach((teamKey, index) => {
                        let aStds = []
                        let aVoornamen = [];
                        oTeams[teamKey].forEach((stdKey, index2) => {
                            console.log("stdKey=", stdKey);
                            aStds.push( {id: index2+1, name: stdKey.stdNaam, stdId: stdKey.stdId, bRoot:false })
                            aVoornamen.push(stdKey.stdNaam.substring(0, stdKey.stdNaam.indexOf(' ')) );
                        });
                        aTeams.push({
                            id: index+1, 
                            bRoot: true,
                            name: teamKey + ' ('+aStds.length+'x) '+ aVoornamen.join(', '),
                            children: aStds
                        })
                    });

                    this.teams = aTeams;
                    //this.$root.$emit("showSnackbar", { text: apiData.sMessage, type: "success", time: 7000 });
                    console.log("in eval.vue, created, getTeamsFromTeamId() this.planGroepMod:", this.teams);
                } else {
                    //this.$root.$emit("showSnackbar", { text: "9) "+apiData.sMessage, type: "error", time: 7000 });
                    console.log("in eval.vue, created, getTeamsFromTeamId() apiData.sMessage:", apiData.sMessage);
                }
            })
        },

        changeTaak: function() {
            // this.getSelTaakRijen();
            // console.log("in mounted edu_all_new.vue. this.selTask.rijen =", this.selTask.rijen)
        },
	},
};
</script>
<style scoped>
    .stdName {
        border:0px solid red; 
        min-width:150px;
        font-weight:600;
    }
</style>
